
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import OrganizationModal from "@/components/modals/forms/settings/OrganizationModal.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
    OrganizationModal,
  },
  data() {
    return {
      entity: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Entity Name",
          key: "entity_name",
          sortable: true,
        },
        {
          name: "Tranche",
          key: "tranche",
          sortable: true,
        },
        {
          name: "Year",
          key: "year",
          sortable: true,
        },
        {
          name: "Target Trainee Number",
          key: "target_trainee_no",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          entity_name:
            "Bangladesh Association of Software and Information Services",
          tranche: "Tranche-1",
          year: "2015-2016",
          target_trainee_no: "5,000",
        },
        {
          id: 1,
          entity_name:
            "Bangladesh Association of Software and Information Services",
          tranche: "Tranche-2",
          year: "2016-2017",
          target_trainee_no: "2,000",
        },
        {
          id: 3,
          entity_name:
            "Bangladesh Association of Software and Information Services",
          tranche: "Tranche-2",
          year: "2017-2018",
          target_trainee_no: "25,000",
        },
        {
          id: 4,
          entity_name:
            "Bangladesh Association of Software and Information Services",
          tranche: "Tranche-3",
          year: "2018-2019",
          target_trainee_no: "10,000",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      lists: [],
      search: "",
      data: {},
      organizations: [],
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
